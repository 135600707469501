import React from 'react'
import styles from './Projects.module.css'
import projects from '../../data/projects.json'
import ProjectCard from './ProjectCard'
import { Fade } from 'react-reveal';
const Projects = () => {
  return <div>
    <Fade down distance='10%' duration={1500}>
     <section className={styles.container} id="projects">
    <h2 className={styles.title}>Projects</h2>
    <div className={styles.projects}>
        {
            projects.map((project, id) => {
                return <ProjectCard key={id} project={project}/>
            })
        }
    </div>
  </section>
    </Fade>
  </div>
}

export default Projects
